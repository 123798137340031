*,body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

.escala {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

}

.escala-grid {
    /* justify-content: space-between; */
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 7px;
    grid-auto-rows: 70px;
}

.box-scroll {
    margin-top: 5%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    max-width: 100%;
}

body::-webkit-scrollbar {
    width: 7px;
}

body::-webkit-scrollbar-track {
    background: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px;
    border: 3px solid white;
}

.valorChck {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    padding: 5% 5% 5% 0%;
    /* margin-left: 22%; */
    color: #fff;
}

.textoCheck {
    margin-left: 1.8rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3rem;
    opacity: 0.4;
    /* float: right; */
}

.button-escala {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 3.5rem;
    margin: 0.5%;
    /* margin-right: 5%; */
    color: rgb(255, 255, 255);
    font-size: 17pt;
    border: 1px solid #A6ACCD;
    border-radius: 5%;
    background-color: #202E84;
}

.select-escala {
    background-color: #3243A6;
    border: 2px solid #fff;
}

/* .inside {

    width: 50%;
} */

.label-check {
    width: 100%;
}
.loading{
    width: 100vw;
    height: 100vh;
    background-color: #03177D;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}
.box-loading{
    width: 300px;
    height: 300px;
}
.wrapper {
    width: 100%;
    /* justify-content: space-between; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    /* grid-auto-rows: 75px; */
}

.wrapper-scroll {
    width: 100%;
    margin-top: 12px;
    min-height: 300px;
    z-index: 1;
    overflow-y: auto;
    height: 100%;
}

.wrapper-scroll::-webkit-scrollbar {
    width: 7px;
}

.wrapper-scroll::-webkit-scrollbar-track {
    background: transparent;
}

.wrapper-scroll::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px;
    border: 3px solid white;
}

.btn-scroll {
    margin-bottom: 10%;
    padding-bottom: 6%;
    /* margin-bottom: 31%; */
    display: flex;
    justify-content: center;
    border-radius: 5%;
    width: 88pt;
    height: 0;
    margin-top: 5%;
    color: #fff;
    z-index: 22;
    background-color: #E9732F;
    font-size: 19pt;
    /* font-family: 'Montserrat', sans-serif; */
    font: normal normal bold 24px/30px 'Montserrat';
}

.select {
    width: 100%
}


.subbuttons {
    margin-bottom: 15%;
    display: flex;
    flex-direction: row;
    /* margin-top: 15%; */
}

.middle {
    width: 0.3%;
    background-color: #B30E00
}

.iconStyles-2 {
    font-size: 3.6rem;
}

.up {
    border-top-left-radius: 7%;
    border-bottom-left-radius: 7%;
    width: 3rem;
    height: 2.5rem;
    border: 0;
    background-color: #EE3B2C
}

.down {
    border-top-right-radius: 7%;
    border-bottom-right-radius: 7%;
    width: 3rem;
    height: 2.5rem;
    border: 0;
    background-color: #EE3B2C
}

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif
}

.container-scroll {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif
}

.container-scroll::-webkit-scrollbar {
    width: 7px;
}

.container-scroll::-webkit-scrollbar-track {
    background: transparent;
}

.container-scroll::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px;
    border: 3px solid white;
}

.box {
    margin-bottom: 17%;
    width: 50%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    
}

.titulo {
    font-size: 20pt;
    margin-bottom: 3%;
    /* margin-right: 11%; */
    color: #fff;
}

.titulo-scroll {
    font-size: 20pt;
    margin-bottom: 0%;
    /* margin-right: 11%; */
    color: #fff;
}

.titulo-dis {
    font-size: 20pt;
    margin-bottom: 3%;
    /* margin-right: 11%; */
    color: #fff;
}


.btnHome {
    cursor: pointer;
    border: none;
    /* border-radius: 5%; */
    border-radius: 3%;
    font: normal normal bold 24px/30px 'Montserrat';
    width: 14rem;
    height: 4rem;
    margin-top: 15%;
    color: #fff;
    background-color: #E9732F;
    font-size: 17pt;
}


.tituloHome {
    font-size: 20px;
    margin-top: 5%;
    font-weight: bold;
    color: #fff;
}

.textoHome {
    font: normal normal bold 24px/30px 'Montserrat';

    font: normal normal medium 20px/28px Montserrat;
    color: #FFFFFF;
    /* letter-spacing: 1px; */
    text-align: center;
    margin-top: 3%;
    color: #fff;
    font-size: 1.1rem;
    opacity: 0.65;
}

.containerHome {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.boxHome {

    justify-content: center;

    align-items: center;
    margin-bottom: 17%;
    /* margin-left: 17%; */
    width: 58%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: start,
}

.texto {
    font: normal normal medium 20px/28px Montserrat;
    color: #FFFFFF;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 3%;
    color: #fff;
    font-size: 1.1rem;
    opacity: 0.65;
    font: normal normal bold 24px/30px 'Montserrat';

}

.btn-9 {
    display: flex;
    justify-content: center;
    border-radius: 5%;
    width: 88pt;
    height: 44pt;
    margin-top: 5%;
    color: #fff;
    z-index: 0;
    background-color: #E9732F;
    font-size: 19pt;
    font-family: 'Montserrat', sans-serif;
}

.btn {
    margin: 2rem 0;
    cursor: pointer;
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: center;
    border-radius: 0.250rem;
    border: none;
    color: #fff;
    background-color: #E9732F;
    font-size: 1.5rem;
    font-weight: bold;
}

.btn-enviar {
    cursor: pointer;
    border: none;
    border-radius: 5%;
    width: 98pt;
    height: 60px;
    margin-top: 5%;
    color: #fff;
    z-index: 22;
    background-color: #E9732F;
    font-size: 24px;
    /* font-family: 'Montserrat', bold; */
    font: normal normal bold 24px/30px 'Montserrat';
}



.iconStyles {
    font-size: 1.8rem;
}

.labels {
    margin-top: 1%;
    width: 100%;
    margin-bottom: 3rem;
    /* display: flex;
        flex-direction: row; */
}

.labels-mobile{
    margin-top: 1%;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.labels-mobile-line{
    margin-top: 1%;
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* align-content: space-between; */
    flex-direction: row;
}


.label-left {
    color: #FAFAFA;
    opacity: 1;
    float: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 14pt
}
.label-left-mobile {
    color: #FAFAFA;
    opacity: 1;
    float: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 12pt
}
.label-text{
    color: #fafafa;
    opacity: 0.75;
    float: left;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    margin-bottom: 10%;
}

.label-right {
    color: #FAFAFA;
    opacity: 1;
    float: right;
    /* padding-right: 4%; */
    font-family: 'Montserrat', sans-serif;
    font-size: 14pt
}
.label-right-mobile {
    color: #FAFAFA;
    opacity: 1;
    float: right;
    /* padding-right: 4%; */
    font-family: 'Montserrat', sans-serif;
    font-size: 12pt
}

.subbuttons {
    display: flex;
    flex-direction: row;
}

.middle {
    width: 0.25rem;
    background-color: #B30E00;
}

.up {
    border-top-left-radius: 7%;
    border-bottom-left-radius: 7%;
    width: 3rem;
    height: 2.7rem;
    border: 0;
    background-color: #EE3B2C
}

.up:hover {
    cursor: pointer;
    outline: none;
    box-shadow: none;
    background-color: #D12C1E
}

.down {
    border-top-right-radius: 7%;
    border-bottom-right-radius: 7%;
    width: 3rem;
    height: 2.7rem;
    border: 0;
    background-color: #EE3B2C
}

.down:hover {
    cursor: pointer;
    outline: none;
    box-shadow: none;
    background-color: #D12C1E
}

.opt {
    display: none;
}
.myTextArea {
    height: 100%;
    padding-left: 10px;
    line-height: 1.5;
    border-radius: 5px;
    background-color: #3243A6;
    border: 1px solid #fff;
    box-shadow: 1px 1px 1px #999;
    width: 100%;
    color: #fff;
    font-size: 17pt;
    margin-bottom: 2%;
}
.myTextArea::placeholder {
    color: #fff;
    font-size: 17pt;
  }
 .text-area{
    width: 100%;
}
.texto-text-area{
      margin-top: 3%;
      float: right;
      color: #fff;
      font-size: 13pt;
}
.myInput {
    /* background: url("../img/icone-chevron-down-desktop.svg") no-repeat right; */
    width: 80%;
    background-color: #03177D;
    border: none;
    border-bottom: 1px solid #A6ACCD;
    color: white;
    padding: 12px;
    font-size: 26px;
    opacity: 1;
}

.myInput-select {
    /* background: url("../img/icone-chevron-down-desktop.svg") no-repeat right; */
    width: 100%;
    background-color: #03177D;
    border: none;
    border-bottom: 1px solid #A6ACCD;
    color: white;
    padding: 12px;
    font-size: 26px;
    /* opacity: 0.6; */
}

.mySelect {
    background: url("../img/icone-chevron-down-desktop.svg") no-repeat right;
    width: 100%;
    /* width: 80%; */
    background-color: #03177D;
    border: none;
    border-bottom: 1px solid #A6ACCD;
    color: white;
    padding: 12px;
    font-size: 26px;
    opacity: 0.6;
}

.mySelect::placeholder {
    color: white;
}

.myInputs::placeholder {
    color: white;
}

.options {

    transition-delay: 2s;
    width: 50%;
    margin-top: 12px;
    min-height: 300px;
    overflow-y: auto;
    padding-right: 6px;
}

.options::-webkit-scrollbar {
    display: none;
    /* width: 5px; */
}

.options::-webkit-scrollbar-track {
    background: transparent
        /* color of the tracking area */
}

.options::-webkit-scrollbar-thumb {
    padding-left: 3%;
    background-color: #fff;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 1px solid #fff;
    /* creates padding around scroll thumb */
}

.item {
    font-size: 16px;
    padding: 10px;
    border: 1px solid #A6ACCD;
    border-radius: 5px;
    color: white;
    background: #202E84;
}

.item-active {
    border: 2px solid #fff;
    background: #3243A6;
}

.item+.item {
    margin-top: 12px;
}

.select-active {
    color: #FFFFFF;
    opacity: 1;
    /* border: 2px solid #fff; */
    /* background: red; */
}
@media (max-width: 1200px){
    .mySelect{
        width: 100%;
    }
}
@media (max-width: 800px){
    .box{
        /* text-align: center; */
        /* background-color: red; */
        width: 80%;
    }
    .mySelect{
        width: 100%;
    }
    .escala-tablet{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 39px;
        grid-auto-rows: 60px;
    }
}

@media (max-width: 500px) {
    .myTextArea {
        height: 100%;
        padding-left: 10px;
        line-height: 1.5;
        border-radius: 5px;
        background-color: #3243A6;
        border: 1px solid #fff;
        box-shadow: 1px 1px 1px #999;
        width: 130%;
        color: #fff;
        font-size: 14pt;
        margin-bottom: 2%;
    }
    /* .myTextArea::placeholder {

        color: #fff;
        font-size: 17pt;
      } */
    .text-area{
        width: 75%;
    }
    .texto-text-area{
          margin-top: 6.5%;
          float: right;
          color: #fff;
          font-size: 13pt;
    }
    .select-escala {
        background-color: #3432a6;
        border: 2px solid #fff;
    }
    .box-scroll {
        width: 100%;
        padding: 0 2rem;
    }

    .wrapper {
        width: 100%;
        /* background-color: green; */
        /* justify-content: space-between; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        /* grid-auto-rows: 100px; */
    }

    .textoCheck {
        margin-left: 1.8rem;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.3rem;
        opacity: 0.4;
        float: right;
    }

    .btn-enviar {
        height: 3.5rem;
        width: 8rem;
        /* text-align: center; */
    }

   
    .button-escala {
        width: 3rem;
        height: 3rem;
        margin: 0.5%;
        color: rgb(255, 255, 255);
        font-size: 17pt;
        /* border: 1px solid rgb(166, 172, 205); */
    }

    .containerHome {
        margin-left: 0;
        overflow: hidden;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .boxHome {
        width: 80%;
        height: none !important;
        margin-bottom: 55%;
    }

    .tituloHome {
        margin-top: 15%;
        margin-bottom: 15%;
        font-size: 20px;
        color: #fff;
    }

    .textoHome {
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 0%;
        color: #fff;
        font-size: 20px;
        opacity: 0.65;
    }

    .container {
        margin-left: 0;
        overflow: hidden;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-family: 'Montserrat', sans-serif;
        position: relative;
    }

    .container-scroll {
        /* background-color: tomato; */
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: 'Montserrat', sans-serif
    }

    .subbuttons {
        margin-bottom: 15%;
        position: absolute;
        bottom: 4%
    }

    .subbutons-wrapper {
        display: flex;
        margin-bottom: 15%;
        bottom: 4%;
        flex-direction: row;

    }

    .btn {
        margin: 2rem 0;
        cursor: pointer;
        display: flex;
        padding: 0.75rem 1rem;
        justify-content: center;
        border-radius: 0.250rem;
        border: none;
        color: #fff;
        background-color: #E9732F;
        font-size: 1.5rem;
        font-weight: bold;
    }

    .btnHome {
        cursor: pointer;
        border: none;
        /* border-radius: 5%; */
        border-radius: 5%;
        font: normal normal bold 24px/30px 'Montserrat';
        width: 13rem;
        height: 3.5rem;
        margin-top: 15%;
        color: #fff;
        background-color: #E9732F;
        /* font-size: 17pt; */
    }

    .up {
        border-top-left-radius: 7%;
        border-bottom-left-radius: 7%;
        width: 3rem;
        height: 2.5rem;
        border: 0;
        background-color: #EE3B2C
    }

    .down {
        border-top-right-radius: 7%;
        border-bottom-right-radius: 7%;
        width: 3rem;
        height: 2.5rem;
        border: 0;
        background-color: #EE3B2C
    }

    .iconStyles {
        font-size: 1.6rem;
    }

    .box {
        justify-content: start;
        width: 85%;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-left: 0
            /* margin-top: 10%; */
    }

    .mySelect {
        background: url("../img/icon-chevron-down.svg") no-repeat right;
        width: 100%;
        background-color: #03177D;
        border: none;
        border-bottom: 1px solid #A6ACCD;
        color: white;
        padding: 5px;
        font-size: 16pt;
        opacity: 0.6;
    }

    .myInput {
        /* background: url("../img/icon-chevron-down.svg") no-repeat right; */
        width: 100%;
        background-color: #03177D;
        border: none;
        border-bottom: 1px solid #A6ACCD;
        color: white;
        padding: 5px;
        font-size: 16pt;
        opacity: 1;
        /* opacity: 0.6; */
    }

    .myInput-select {
        /* background: url("../img/icon-chevron-down.svg") no-repeat right; */
        width: 100%;
        background-color: #03177D;
        border: none;
        border-bottom: 1px solid #A6ACCD;
        color: white;
        padding: 5px;
        font-size: 16pt;
        opacity: 1;

    }

    .select-active {
        color: #FFFFFF;
        opacity: 1;
    }

    .middle {
        width: 3%;
        background-color: #B30E00;
    }

    .options {
        width: 100%;
        margin-top: 12px;
        min-height: 300px;
        z-index: 1;
        overflow-y: auto;
    }

    .box img {
        margin-bottom: 5%;
    }

    .titulo {
        font-size: 20pt;
        margin-top: -10%;
        margin-bottom: 20%;
    }

    .titulo-scroll {
        font-size: 20pt;
        /* margin-top: -10%; */
        /* margin-bottom: 4%; */
    }

    .titulo-dis {
        font-size: 20pt;
        margin-top: -10%;
        /* margin-bottom: 20%; */
    }


    .texto {
        font-size: 0.8rem;
    }
}
