.root{
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);  
  }

  .container2{
    max-width: 768px !important;
    overflow: auto;
    width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto;
  }

  .container-body{
    padding-top: 6rem;
    padding-bottom: 2rem;
  }

  .container-footer{
    margin-bottom: 4rem;
  }
 
  /* 1280x800 pixels  */

  /* 104598
  1096859
  1048653
  1080118
  1083258
  503192
  Taça de vinho Amber 365ml
  Taça de vinho tinto Amber 460ml
  ST61593
  ST61597
  ST61594
  ST61544 - 0
  ST61543
  ST61522
  ST61515
  ST61592
  ST61526
  ST61549
  ST61548
  ST61553
  ST61523
  ST61514 */


